<template>
  <div class="main">
    <div class="container">
      <div class="fud_box">
        <div class="news_" v-if="detail.title">
          <div class="news_header">
            <div class="title">{{ detail.title }}</div>
            <div class="time">{{ detail.time }}</div>
          </div>
          <div class="news_detail">
            <template v-if="detail.img.length > 0">
              <img
                v-for="(item, index) in detail.img"
                :key="index"
                :src="$OSSURL + item"
              />
            </template>
            <div class="text" v-html="detail.desc"></div>
            <div class="backBtn" @click="pageBack"></div>
          </div>
        </div>
        <ul class="news_nav_list">
          <li
            class="item"
            @click="toDetail(item.id)"
            v-for="(item, index) in list"
            :key="index"
          >
            <img v-if="item.titleimg != ''" :src="$OSSURL + item.titleimg" />
            <div class="name">{{ item.title }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { DATA } from '@/data/f_updates.js';
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      year: '',
      id: this.$route.query.id,
      detail: {},
      list: [],
    };
  },
  created() {
    this.getDetail();
  },

  methods: {
    pageBack() {
      this.$router.go(-1);
    },
    toDetail(id) {
      this.$router.replace({
        path: '/Festival/UpdatesDetail',
        query: { id },
      });
    },
    getDetail() {
      this.detail = {};
      this.detail = DATA.find(item => {
        return item.id == this.id;
      });
      console.log(this.id);
      console.log('更新');
      console.log(this.detail);
      this.getOrder();
    },
    getOrder() {
      this.list = [];
      for (let i = 0; i < 4; i++) {
        if (DATA[i].id == this.id) {
          continue;
        } else {
          this.list.push(DATA[i]);
        }
      }
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化

        this.id = newVal.query.id;
        this.getDetail();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
.fud_box {
  display: flex;

  .news_ {
    width: 760px;
    flex: 0 0 760px;
    margin-bottom: 100px;
  }
  .news_nav_list {
    flex: 1;
    margin-left: 50px;
    margin-top: 50px;
    .item {
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 2px solid #efefef;
      cursor: pointer;
      img {
        width: 100%;
      }
      .name {
        line-height: 24px;
        height: 50px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}
@media (max-width: 1000px) {
  .fud_box {
    display: block;
    .news_ {
      width: 100%;
    }
    .news_nav_list {
      display: none;
    }
  }
}
</style>
