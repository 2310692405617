export const DATA = [
  {
    id: 1,
    title:
      '2022 ECI FESTIVAL will be held at the China International Import Expo in Shanghai in November 2022',
    desc: `ECI FESTIVAL will be held at the China International Import Expo in Shanghai in November 2022. At that time, the 2022 ECI Awards International Digital Innovation Awards Ceremony will also be held at the same time, and awards such as the annual innovative person, the annual innovative organization, and the annual innovative case will be awarded.`,
    img: ['sucai/fUp/2022b.jpg'],
    year: 2022,
    titleimg: 'sucai/fUp/2022.png',
    time: '',
  },
  {
    id: 2,
    title: '2019 ECI FESTIVAL - SHANGHAI PRESS CONFERENCE',
    desc: `ECI Festival press conference has been held on September 12, 2019 in Shanghai IPMALL.<br>
    This year's ECI Festival will take "innovation and unlimited opportunities" as the theme and will be jointly organized by the ECI Awards Organizing Committee and IP MALL.
    <br>The International Entrepreneurs, Creatives, & Innovators Association and China Business Advertising Association Business Innovation Committee will be the advisory unit. The special co-organizer of the ECI Festival will be conducted by Dot Connecter. Meanwhile, IPMALL media
    and IPMALL exhibition will take the responsibility as the joint organizer of the 2019 ECI Festival. 
    
    <br><br>
    ECI Festival 2019 is jointly organized by the ECI Awards Organizing Committee and IP MALL. It is co-sponsored by 23 ECI  countries and regions as members to build the most influential resource aggregation platform among the global innovation industry chains.
    <br>
    At the press conference, the chairman of the IECIA, Su Xiong, said in his opening speech: "China has become a hot word in the world in recent years. The ‘new four inventions’ are all causing waves in the world. All these are inseparable from innovation, and China's innovation has also become a new source of progress in the whole sphere, which has a more prominent impact on the world's innovation field.”
    <br>
    In addition, Dr. Owen Jia, Executive Chairman of ECI Awards, gave a comprehensive explanation of the content structure and direction of the 2019 ECI Festival. He said that in this festival, we will put forward three highlights under the leadership of "Innovation Values Driven, Innovation Mission Driven".
    <br>
    Firstly, in this year’s ECI Festival, we will launch the Council and issue the ECI Global Innovation Declaration with the representatives of 23 member countries around the world. 
    <br>
    Secondly, ECI believes that education is the only way to hatch a new generation of innovation. We need generations of innovative talents to change the world and create the future. Therefore, we have established the forum of chief innovation officers of ECI Innovation Academy, and launched a global innovation tour to achieve the next generation of chief innovation officers with the innovative curriculum system and scenario case teaching method of "science and technology innovation + capital operation + brand aesthetics + creative communication + business management". 
    <br>
    Thirdly, this year ECI will establish China's independent brand innovation forum will be guided by innovation, and put forward the path of innovation and development in line with China's own brand, and carry forward the spirit of self brand innovation and commendation success model.China's independent brand innovation forum needs to innovate in promoting digital transformation of Chinese traditional enterprises, promoting transformation of Chinese products to Chinese brands, and innovating and shaping the good image of Chinese independent brands.
    <br>
    Last but not the least, on behalf of the ECI organizing committee at the press conference, the famous Chinese choreographer, Miss Tian Fang, officially launched the “ECI TIMES“ series of innovative personage legendary documentaries. ECI TALK will focus on the annual innovators to launch IP planning and production of character interviews, reality shows, feature films, movies and TV dramas. We also invite innovators of the year to share innovative life and ideas with the younger generation.
    <br><br>
    
    2019 ECI FESTIVAL - SHANGHAI
    <br>Date: 11 December
    <br>Location: SHANGHAI
    <br><br>
    This year, ECI will provide more systematic and professional services to the global digital business with IP MALL. This will enable more cities, industries, enterprises and brands to create more opportunities and values for innovation, work together to create a ECI innovation industry ecosystem, bring innovation into life, and promote China's innovation to influence the world. Let the world share "China's opportunity".`,
    img: ['sucai/fUp/2019b.jpg'],
    year: 2019,
    titleimg: 'sucai/fUp/2019.png',
    time: ``,
  },
  {
    id: 3,
    title:
      'Wow! 2018 Maritime Silk Road ECI International Innovation Festival to Create a Strong Innovation Engine.',
    desc: `From Zheng He's voyage to the 21st Century Silk Road, from exporting silk, porcelain, tea and other products to high and new technologies such as Internet technology and intelligent manufacturing. Nowadays, the continuous progress and subversion of China's science, technology and culture have amazed the world and made people imagine the future.
<br>
    The first International Digital Business Innovation Festival on the Maritime Silk Road will be held in Quanzhou from June 22 to 23 under the auspices of the ECIAwards Award Organizing Committee and the Fengze District Government of Quanzhou. This time, it will give you different exclamation, "Wow" after all!
    <br><br>
    Wow! Innovation drives the future!
    <br><br>
    In the 21st century, with a new round of scientific and technological revolution and industrial transformation, cultural production mode has been accelerated and become an inexhaustible driving force for cultural development. High and new technologies, such as artificial intelligence, virtual reality, information network, big data, cloud computing, Internet of Things, have been widely permeated into the creation, production and transmission of cultural products and services. Every link of broadcasting and consumption. The first International Digital Business Innovation Festival on the Marine Silk Road will be based on the "Marine Silk Road Culture" for international exchanges and cooperation, focusing on "intelligent manufacturing, cultural IP, industrial upgrading, intelligent marketing" to explore new opportunities for future development.
    <br><br>
    In this era of evolutionary innovation, these hot topics and opinions will lead us to the forefront of innovation in the world.
    <br>Where are the new opportunities for the maritime Silk Road?
    <br>What aspects of China made 2025 will be fully deployed?
    <br>How can we link up with the world for future trade innovation?
    <br>What is the significance of upgrading and upgrading the industry?
    <br>How to play "traditional + new retail" under the background of consumption upgrading?
    <br>What are the marketing innovations of Chinese brands?
    <br><br>
    At the SUBMIT forum of the International Digital Business Innovation Festival of the Maritime Silk Road on June 22, representatives of countries and regions along the Silk Road as well as guests from all walks of life discussed in depth the great challenges and innovations of the international digital business innovation of the Maritime Silk Road.
    <br><br>
    At the same time, the annual "ECIAwards International Digital Business Innovation Award Award Award Award" will be held here, together with the award-winning units from around the world, to create the year's most visible international innovation and application feast. The ECIAwards International Digital Business Innovation Award is an international award based on the criterion of "Innovative Application". It honors innovative works of commercial value (including business model innovation, product innovation, technological innovation, service innovation, management innovation, marketing innovation, etc.) and upholds the leadership of global business innovation standard setters. First, position, encourage, create, guide innovation, and promote the world's business innovation and development. The awards will be announced at the ECI Awards Awards in 2018, and more industry innovation projects and opinion leaders will be involved to set up innovation benchmarks and create new business space. Which works can win gold, silver and bronze prizes? Who can win the grand prize? The mystery will be unveiled in the 2018 ECI Awards awards ceremony.
    <br><br>
    In addition, ECI will link technology, capital and brands through innovation exhibitions, award-winning work exchanges and resource docking sessions: not only will embassies and consulates of countries along the Hess Line in China and technology companies display the latest and hottest black technological products from various countries, but also the industry's top innovative force will bring together the 2018 ECI Awards. At the product exchange meeting, we share the successful experience of excellent works and future innovation trends, and more innovative projects/brands interact closely with industry mentors, capitalists and marketing resources at the resource docking meeting. The three big plates will surely carry out "Wow" and innovation in the end.
    <br><br>
    Wow! Innovation ignites enthusiasm!
    <br><br>
    At a news conference held on April 19, Sri Lanka, Qatar and Pakistani diplomats in China unanimously said that "the International Digital Business Innovation Festival on the Maritime Silk Road will promote civil exchanges in the international arena". The first "International Digital Business Innovation Festival on the Maritime Silk Road" in June will be held in Fengze District of Quanzhou City, which will bring more opportunities for foreign cooperation and exchanges and open up the road to the outside world. In addition, a special award for "intelligent manufacturing" was set up at the ECI Awards Award Ceremony in 2018 to commend the advanced demonstration projects of Intelligent Manufacturing in cities along the Silk Road, such as Quanzhou, and to promote the creativity and urban development of Quanzhou.
    <br><br>
    As a new landmark of Quanzhou's fashion, the event is a comprehensive park which combines cultural creation with tourism and has won many honors such as "National Advertising Industry Park" and "National Popular Creative Space". The event is not only a carnival of innovation in the industry, but also a gathering of global hot topics and IP. The enthusiasm of young audiences to participate and interact will be ignited, and the influence of innovation will continue to ferment.
    <br><br>
    This is the most innovative and influential "big gathering" and an innovation event to help the common prosperity along the sea silk road. Locked in the first "International Digital Business Innovation Festival on the Maritime Silk Road". June 22-23, give you a digital business wonder!`,
    img: ['sucai/fUp/2018b.jpg'],
    year: 2018,
    titleimg: 'sucai/fUp/2018.png',
    time: '',
  },
  {
    id: 4,
    title: '2018 Maritime Silk Road ECI International Innovation Festival',
    desc: `On June 22, the 2018 Maritime Silk Road ECI International Innovation Festival was held in Quanzhou, Fujian Province. Hundreds of innovative representatives and industry leaders, media representatives and investors from around the world attended the event. The participants from different industries shared with the participants on the five major plates.

    <br>ECI keynote speech delivered by US expert committee, Tulay Ozkaya Spicker, an expert on intelligent industrial chain in the US.
    
    <br>As the world's leading international award in the field of digital commerce and with "innovative applications" as its evaluation criteria, the ECI Awards International Digital Commerce Innovation Award establishes standards by recognizing innovative works of commercial value (including business models, products, technologies, services, management, marketing, etc.) and innovative people and organizations. Rods promote innovation and define the future. The awards are divided into six categories: technological innovation, product innovation, service innovation, business model innovation, marketing innovation and management innovation.
    
    <br>For ECI Awards, the definition of "innovation" is not only to help innovators in the industry to set a benchmark, more importantly, innovative ways of thinking to communicate, presenting high-quality technological achievements at the same time, in the path of innovation to go further and better.
    
    <br>In her opening speech, Ms. Chen, Vice-Chairman of IECIA International Digital Business Innovation Association, founder and CEO of WE Marketing Group, reviewed the awards of ECI Awards in recent years and summarized the development trend of innovative thinking. It is reported that the 2018 ECI Awards entries totaled 1168 pieces, covering VR, intelligent medical equipment, large data platform, technology marketing platform, finance, service-oriented robots, education, e-commerce, catering and other industries. ECI Awards takes "innovation" as the evaluation criterion, and "innovation" as the focus of attention of the industry. It also leads different industries to explore innovative development forms such as "innovative thinking", "innovative technology" and "innovative business model". Ms. Chen mentioned that ECI Awards hopes to set an example for the industry by selecting the most representative innovations and promote the development of Chinese innovation and even global business innovation.
    
    <br><br>
    Li Xisha, President of China Business Advertising Association, Doctoral Tutor of School of Journalism and Communication, Xiamen University, Chen Pei-ai, Member of the Teaching Steering Committee of Journalism, Ministry of Education, Executive Vice-Chairman of IECIA International Digital Business Innovation Association, Su Xiong, Chairman of Asian Advertising Union, and IECIA International Association for Digital Business Innovation issued the award. Dr. Jia Lijun, Executive Chairman of ECI Awards, Executive Vice-Chairman of ECI Awards, Co-founder of Panda Media, Dr. Wu Xiaoming, CEO of Ansopa Taiwan, Dr. Ji Zhiqian, Executive Chairman of the Foundation Committee of the Chinese Society of Artificial Intelligence, Dr. Chen Zhicheng, Oracle Oracle Data Scientist Zhang Hancheng, IDG Digital Media Xu Weiming, vice president of physical development, attended the award ceremony as a guest of honor.
    
    
    <br>Yangshi Advertising Co., Ltd. Shanghai Branch entries "for love to start STEP UP FORLOVE" in the motivation of the marketing innovation group competition to stand out, won the annual marketing innovation award. In recommending the work, the jury said, "In 30 days, 100 enterprises, 120 million people participated and 150 million yuan was raised. What is more important than capital is that it inspires more people and more enterprises in public interest. It pioneered a win-win model among individuals, enterprises, platforms and donors, and made the concept of "doing good on a daily basis" advocated by Chinese traditional culture widely realized.
    
    <br>Grand Prix
    
    <br>As the most innovative award of the year, the ECI Awards are selected from the highest-scoring gold medals in all categories. In the end, SAIC Group won the 2018 ECI Awards for its entry "Birth of Intelligent New Species". In recommending the work, the jury said, "SAIC Ali Internet Automobile has successfully realized the internetization of automotive products and the integration of Internet products and automobiles, and sublimated automobiles from substitution tools to intelligent living conditions. This is not only the digital technology evolution of large-scale chemical industry, but also the solid foundation of the entity of digital Internet industry. In fact, it provides a benchmarking milestone for the future digital business innovation. "
    
    <br><br>
    Yu Jingmin, deputy general manager of SAIC Group, accepted the award of the grand prize.
    
    <br>Through the annual selection of global digital business innovations, the definition of "innovation" by ECI Awards is also evolving. Innovation can promote productivity and industrial structure; innovation can optimize resources and create commercial value; innovation can promote diversified development and activate regional economic students.
    
    <br><br>
    This 2018 ECI Awards competition comes from a worldwide public collection of innovative works, representing the world's most professional quality and innovative thinking in different industries. Through in-depth analysis of the works, a panel of senior industry experts fully tapped the innovativeness and value of its brand products, and selected the most innovative works with commercial value and innovation inspiration.
    
    <br><br>
    
    Annual marketing innovation award
    
    <br>Yangshi Advertising Co., Ltd. Shanghai Branch entries "for love to start STEP UP FORLOVE" in the motivation of the marketing innovation group competition to stand out, won the annual marketing innovation award. In recommending the work, the jury said, "In 30 days, 100 enterprises, 120 million people participated and 150 million yuan was raised. What is more important than capital is that it inspires more people and more enterprises in public interest. It pioneered a win-win model among individuals, enterprises, platforms and donors, and made the concept of "doing good on a daily basis" advocated by Chinese traditional culture widely realized.
    
    <br>Grand Prix
    
    <br>As the most innovative award of the year, the ECI Awards are selected from the highest-scoring gold medals in all categories. In the end, SAIC Group won the 2018 ECI Awards for its entry "Birth of Intelligent New Species". In recommending the work, the jury said, "SAIC Ali Internet Automobile has successfully realized the internetization of automotive products and the integration of Internet products and automobiles, and sublimated automobiles from substitution tools to intelligent living conditions. This is not only the digital technology evolution of large-scale chemical industry, but also the solid foundation of the entity of digital Internet industry. In fact, it provides a benchmarking milestone for the future digital business innovation. "
    
    
    <br>Yu Jingmin, deputy general manager of SAIC Group, accepted the award of the grand prize.
    
    <br>Through the annual selection of global digital business innovations, the definition of "innovation" by ECI Awards is also evolving. Innovation can promote productivity and industrial structure; innovation can optimize resources and create commercial value; innovation can promote diversified development and activate regional economic students.`,
    img: ['sucai/fUp/2018b_21.jpg', 'sucai/fUp/2018b_22.jpg'],
    year: 2018,
    titleimg: 'sucai/fUp/2018_2.png',
    time: '',
  },
  {
    id: 5,
    title: '2017 ECI Festival North America',
    desc: `2017 ECI Festival North America
    2017 ECI Festival North America was held from August 3, 20／17 to August 11, 2017. Several industry experts and local government officials attended the ribbon cutting ceremony. 
    
    
    <br> <br>
    
    ECI delegation visited Facebook on August 4, 2017.  
    
    
    <br> <br>
    
    ECI Innovation Workshop took place on the campus of Stanford University on August 5, 2017. Professor Jerry I. Porras was invited to make a presentation.  
    
    <br> <br>
    
    
    ECI delegation visited Google’s headquarter and had a better understanding of the R&D direction of AI, especially speech recognition technology. “ Cloud + Machine Learning + Smart phone = Magic is Normal”
    
    
    <br> <br>
    
    ECI delegation visited California State Capitol in Sacramento and were received as VIP guests by California Senator Jim Nielsen.  
    
    
    <br> <br>
    
    ECI delegation visited Alta Devices, owned by Hanergy—winner of 2017 ECI Outstanding Organizations in China US Business Innovation Award and met with its executives. 
    
    
    <br> <br>
    
    Intel’s museum was the last stop of the same-day visit. `,
    img: [
      'sucai/fUp/2017b_11.jpg',
      'sucai/fUp/2017b_12.jpg',
      'sucai/fUp/2017b_13.jpg',
      'sucai/fUp/2017b_14.jpg',
      'sucai/fUp/2017b_15.jpg',
      'sucai/fUp/2017b_16.jpg',
      'sucai/fUp/2017b_17.jpg',
      'sucai/fUp/2017b_18.jpg',
      'sucai/fUp/2017b_19.jpg',
    ],
    year: 2017,
    titleimg: 'sucai/fUp/2017.png',
    time: '',
  },
  {
    id: 6,
    title: '2017 ECI Festival Beijing Count Down',
    desc: ``,
    img: [
      'sucai/fUp/2017b_21.jpg',
      'sucai/fUp/2017b_22.jpg',
      'sucai/fUp/2017b_23.jpg',
      'sucai/fUp/2017b_24.jpg',
    ],
    year: 2017,
    titleimg: 'sucai/fUp/2017_2.jpg',
    time: '',
  },
  {
    id: 7,
    title: 'Maritime Silk Road ECI International Innovation Festival',
    desc: `ECI Awards and the People's Government of Quanzhou announced that they will jointly run the First Maritime Silk Road ECI International Innovation Festival in Quanzhou, Fujian Province on June 22 and 23, and jointly promote the economic and cultural development of the Maritime Silk Road and exchanges and cooperation under the major initiative to build the "twenty-first Century Maritime Silk Road".
    <br><br>The event was co-organized by the ECIA, CAAC and People's Government of the Quanzhou city, Fujian Province; Leaders from Quanzhou People's Government and other ECIA representatives attended the press conference. In addition, innovation representatives from the fields of technology, investment, marketing and others, in addition to ECI Awards's final judging experts as well as representatives from more than 20 authoritative media bodies such as China com, Huanqiu com, China News com, CNR, GMW cn, CCTV Online, Tencent, NetEase and Phoenix Online Sina participated in this press conference to witness the launching ceremony of the First Maritime Silk Road ECI International Innovation Festival.`,
    img: ['sucai/fUp/2017b_3.jpg'],
    year: 2017,
    titleimg: 'sucai/fUp/2017_3.png',
    time: '',
  },
];
